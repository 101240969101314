import { useCallback, useEffect, useMemo } from "react";
import {
  getContact,
  getContacts,
  getContactsNumbers,
  getHasAnyClient,
} from "api-services/definitions/contacts";
import { useApiGet, useApiGetInfinite } from "api-services/endpoints";
import { kebabCase, snakeCase } from "lodash";
import moment from "moment";
import { KeyedMutator } from "swr";
import { objectInputType, ZodAny, ZodOptional, ZodString } from "zod";

import { useAuth } from "@contexts/auth";
import { useCollection } from "@contexts/data";
import {
  ClientStatusEnumType,
  ClientType,
  ClientTypeEnumType,
} from "@lib/data/schemas/client";

export const useContacts = (
  withAssigneeIds?: boolean
): {
  data?: Array<ClientType | (ClientType & { assigneeId?: string })>;
  contactsMap?: Map<string, ClientType>;
  active?: Array<ClientType | (ClientType & { assigneeId?: string })>;
  loading: boolean;
  mutate: KeyedMutator<{
    data: objectInputType<
      {
        id: ZodString;
        assigneeId: ZodOptional<ZodString>;
      },
      ZodAny,
      "strip"
    >[];
    cursorId?: string | undefined;
  }>;
} => {
  const { oid } = useAuth();
  const {
    data: apiData,
    loading,
    mutate,
  } = useApiGet(
    getContacts,
    { orgId: oid! },
    {},
    {
      dedupingInterval: 60000,
    }
  );

  const response = useMemo(() => {
    const contacts = apiData?.data as ClientType[] | undefined;
    const data = contacts?.filter((contact) => contact.status !== "merged");

    const active = data?.filter(
      (contact) => contact.status !== "archived" && contact.status !== "deleted"
    );

    const withAssignees = withAssigneeIds
      ? data?.map((contact) => {
          const assigneeId = contacts?.find((c) => c.id === contact.id)
            ?.assigneeId;
          return { ...contact, assigneeId };
        })
      : undefined;

    return {
      data: withAssigneeIds ? withAssignees : data,
      active,
    };
  }, [apiData, withAssigneeIds]);

  const contactsMap = useMemo(() => {
    if (!response?.data) return undefined;
    const contacts = response?.data as ClientType[] | undefined;
    return new Map(contacts?.map((contact) => [contact.id, contact]));
  }, [response?.data]);

  return {
    ...response,
    contactsMap,
    loading: loading,
    mutate,
  };
};

export const useContactsNumbers = (): {
  loading: boolean;
  total?: number;
  active?: number;
} => {
  const { oid } = useAuth();
  const { data: apiData, loading } = useApiGet(
    getContactsNumbers,
    { orgId: oid! },
    undefined,
    {
      dedupingInterval: 60000,
    }
  );

  return {
    ...apiData,
    loading: loading,
  };
};

export const useHasAnyClient = (
  status?: ClientStatusEnumType,
  clientType?: ClientTypeEnumType
): {
  loading: boolean;
  hasAnyClient?: boolean;
} => {
  const { oid } = useAuth();
  const { data: apiData, loading } = useApiGet(
    getHasAnyClient,
    { orgId: oid! },
    { ...(status && { status }), ...(clientType && { clientType }) },
    {
      dedupingInterval: 60000,
    }
  );

  return {
    hasAnyClient: apiData,
    loading: loading,
  };
};

export const useContact = (contactId?: string | null) => {
  const { oid } = useAuth();
  const { parentContact } = useParentContact(contactId);

  const {
    data: apiData,
    isLoading,
    loading,
    mutate,
    error,
  } = useApiGet(
    oid && contactId ? getContact : undefined,
    oid && contactId ? { orgId: oid, contactId } : undefined,
    undefined,
    {
      dedupingInterval: 60000,
    }
  );

  const contact = apiData?.data as ClientType | undefined;

  return {
    isLoading,
    /** @deprecated Returns [true] if [contact] is undefined which may not be what you need. Use isLoading instead. */
    loading,
    contact,
    parentContact,
    mutate,
    error,
  };
};

export const useParentContact = (contactId: string | undefined | null) => {
  const { oid } = useAuth();

  const { data: companies, loading: loadingCompanies } = useApiGet(
    oid && contactId ? getContacts : undefined,
    oid && contactId ? { orgId: oid } : undefined,
    { clientType: "company" },
    {
      dedupingInterval: 60000,
    }
  );

  const { data: families, loading: loadingFamilies } = useApiGet(
    oid && contactId ? getContacts : undefined,
    oid && contactId ? { orgId: oid } : undefined,
    { clientType: "family" },
    {
      dedupingInterval: 60000,
    }
  );

  const parentContact = useMemo(() => {
    const potentialParents = (companies?.data ?? []).concat(
      families?.data ?? []
    ) as ClientType[];
    return potentialParents?.find(
      (client) => client.members?.some((member) => member.id === contactId)
    );
  }, [companies, families, contactId]);

  return {
    loading: loadingCompanies || loadingFamilies,
    parentContact,
  };
};

const getColor = (status: string) => {
  switch (status) {
    case "shared":
      return "blue-450";
    case "paid":
    case "submitted":
    case "completed":
    case "confirmed":
    case "sent":
      return "blue-500";
    case "draft_created":
    case "created":
    case "created_empty":
    case "title_added":
      return "grey-500";
    case "updated":
    case "rescheduled":
      return "yellow-500";
    case "incompleted":
    case "cancelled":
    case "archived":
    case "deleted":
    case "unread":
      return "peach-500";
    case "viewed":
    case "created_from_product":
      return "action-500";
    default:
      return "gray-500";
  }
};

export const useExtendedContacts = () => {
  const { data: contacts, loading } = useContacts(true);
  const { data: labels } = useCollection("labels");

  return useMemo(() => {
    const data = contacts?.map((client) => {
      const messageDate = client.stream?.lastMessageAt
        ? moment(client.stream?.lastMessageAt).add(-4, "seconds")
        : undefined;
      const latestArtefactActivityDate = client.latestActivity?.createdAt;
      let activity;

      if (client.status === "archived") {
        activity = {
          name: "Client archived",
          color: "grey-500",
          date:
            client.archivedAt ||
            client.latestActivity?.createdAt ||
            client.createdAt,
        };
      } else if (!messageDate && !latestArtefactActivityDate) {
        activity = {
          name: "Client created",
          color: "black-ink",
          date: client.createdAt,
        };
      } else {
        activity =
          (messageDate || new Date(0)) <
          (latestArtefactActivityDate || new Date(0))
            ? {
                name: client.latestActivity!.name,
                color: getColor(client.latestActivity!.actionId),
                date: latestArtefactActivityDate,
              }
            : {
                name: "Chat message",
                color: "grey-500",
                date: messageDate,
              };
      }

      const {
        firstName,
        lastName,
        labels: clientLabels,
        email,
        emails = [],
      } = client;

      const labelNames = clientLabels
        ?.map((id) => labels?.find((l) => l.id === id)?.title || "")
        .join("_");

      const searchKey = kebabCase(
        [firstName, lastName, email, labelNames, ...emails].join(" ")
      );

      return { ...client, latestMergedActivity: activity, searchKey };
    });

    return { data, loading };
  }, [labels, contacts, loading]);
};

export const useSearchExtendedContacts = (
  oid: string,
  aid: string,
  orderBy: "name" | "activity",
  optionalProps: {
    searchKey?: string;
    clientType?: string;
    assigneeId?: string;
    includeDeleted?: boolean;
    excludeArchived?: boolean;
  } = {}
) => {
  const { data: labels } = useCollection("labels");
  const {
    organizationAccounts: { accounts },
  } = useAuth();
  const limit = 20;

  const { searchKey, clientType, assigneeId, includeDeleted, excludeArchived } =
    optionalProps;

  const clientSideSearch = useMemo(() => {
    if (!accounts) return false;
    const accountId = assigneeId || aid;
    const account = accounts.find((acc) => acc.id === accountId);

    return (
      account?.accessType !== "full" &&
      ["family", "company"].includes(clientType ?? "")
    );
  }, [accounts, aid, assigneeId, clientType]);

  const {
    data,
    loading: loadingContactIds,
    setSize,
    size,
    mutate,
  } = useApiGetInfinite(
    getContacts,
    { orgId: oid! },
    {
      ...(searchKey && !clientSideSearch && { searchKey }),
      ...(assigneeId && { assigneeId }),
      ...(clientType && { clientType }),
      orderBy: orderBy,
      ...(!clientSideSearch && { limit: limit.toString() }),
    }
  );

  useEffect(() => {
    if (searchKey && !clientSideSearch) {
      setSize(1);
    }
  }, [clientSideSearch, searchKey, setSize]);

  const contacts = data?.flatMap((page) => page.data);

  const loadMore = useCallback(() => {
    if (loadingContactIds) return;
    if (contacts?.length === size * limit) {
      setSize(size + 1);
    }
  }, [contacts?.length, loadingContactIds, setSize, size]);

  return useMemo(() => {
    const data = contacts
      ?.filter((contact) => {
        if (contact.status === "merged") return false;
        if (contact.status === "archived" && excludeArchived) return false;
        if (includeDeleted) return true;
        return contact.status !== "deleted";
      })
      .map((client) => {
        const assigneeId =
          client.assigneeId ??
          contacts?.find((c) => c.id === client.id)?.assigneeId;

        const messageDate = client.stream?.lastMessageAt
          ? moment(client.stream?.lastMessageAt).add(-4, "seconds")
          : undefined;
        const latestArtefactActivityDate = client.latestActivity?.createdAt
          ? moment(client.latestActivity?.createdAt)
          : undefined;
        let activity;

        if (client.status === "archived") {
          activity = {
            name: "Client archived",
            color: "grey-500",
            date:
              client.archivedAt ||
              client.latestActivity?.createdAt ||
              client.createdAt,
          };
        } else if (!messageDate && !latestArtefactActivityDate) {
          activity = {
            name: "Client created",
            color: "black-ink",
            date: client.createdAt,
          };
        } else {
          activity =
            (messageDate?.toDate().getTime() ?? new Date(0).getTime()) <
            (latestArtefactActivityDate?.toDate().getTime() ??
              new Date(0).getTime())
              ? {
                  name: client.latestActivity!.name,
                  color: getColor(client.latestActivity!.actionId),
                  date: latestArtefactActivityDate,
                }
              : {
                  name: "Chat message",
                  color: "grey-500",
                  date: messageDate,
                };
        }

        const { firstName, lastName, email, emails = [] } = client;

        const clientLabels = (client.labels ?? []) as string[];

        const labelNames = clientLabels
          .map((id) => labels?.find((l) => l.id === id)?.title || "")
          .join("_");

        const names = [firstName, lastName].map((item) =>
          snakeCase(item).replace(/_/g, "")
        );

        const searchKey = kebabCase(
          [...names, email, labelNames, ...emails].join(" ")
        );

        return {
          ...client,
          assigneeId,
          latestMergedActivity: activity,
          searchKey,
          assigneeIds: client.assigneeIds ?? [],
        };
      });

    return { data, loading: loadingContactIds, loadMore, mutate };
  }, [contacts, loadingContactIds, loadMore, mutate, includeDeleted, labels]);
};
